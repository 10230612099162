import React from "react";
import mainlogo from "./tajexpress.png";
import "./App.css";

const HomePage = () => {
  return (
    <div className="hero">
      <img
        className="Image"
        src={mainlogo}
        alt="Taj Xpress"
        width="30%%"
        height="20%"
      />
      <h1>Taj-Xpress</h1>
      <p>Love at first bite!</p>
      <p className="comingsoon">COMING SOON</p>
      <p>
        Our website is currently under construction. Stay tuned for updates.
      </p>
      <h2>FEATURES SOON</h2>
      <ul>
        <li>Specials of the Day</li>
        <li>New Menu Items</li>
        <li>Online Ordering</li>
      </ul>
      <br />
      <br />
      <br /> <br />
      <br />
      <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
      <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />{" "}
      <br />
    </div>
  );
};

export default HomePage;
